import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.aipro.ro',
  timeout: 30000,
});
export const getCompanyStats = async () => {
  try {
    const response = await api.get('/stats');
    return response.data;
  } catch (error) {
    console.error('Error fetching stats:', error);
    return {
      total: 0,
      active: 0,
      inactive: 0,
      byRegion: {}
    };
  }
};
export const getCAENStats = async (county = null) => {
  try {
    const params = new URLSearchParams();
    if (county) {
      params.append('county', county);
    }

    const response = await fetch(`https://api.aipro.ro/caen-stats?${params.toString()}`);
    if (!response.ok) {
      throw new Error('Failed to fetch CAEN statistics');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching CAEN stats:', error);
    throw error;
  }
};
export const updateCompany = async (cui) => {
  try {
    const response = await fetch(`https://api.aipro.ro/update-company?cui=${cui}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to update company');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating company:', error);
    throw error;
  }
};

// Actualizăm și getCompanyDetails pentru a include timestamp-ul ultimei actualizări
export const getCompanyDetails = async (cui) => {
  try {
    const response = await fetch(`https://api.aipro.ro/get?cui=${cui}`, {
      headers: {
        'Accept': 'application/json',
      },
    }); 

    if (!response.ok) {
      throw new Error('Failed to fetch company details');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching company details:', error);
    throw error;
  }
};
export const searchCompaniesByRegion = async (region, page = 1) => {
  try {
    const response = await api.get('/companies-by-region', {
      params: {
        region,
        page,
        perPage: 20
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching companies by region:', error);
    throw error;
  }
};
export const getCompanyCount = async () => {
  try {
    const response = await api.get('/total');
    return response.data;
  } catch (error) {
    console.error('Error fetching company count:', error);
    return { count: 0 };
  }
};
export const searchCompanies = async ({ search, filters = {}, page = 1, perPage = 10, searchBy = 'all' }) => {
  try {
    const params = new URLSearchParams();
    
    // Adăugăm parametrii de bază
    params.append('page', String(page));
    params.append('searchBy', String(searchBy));
    params.append('perPage', String(perPage));

    // Adăugăm textul de căutare
    if (typeof search === 'string' && search.trim()) {
      params.append('searchText', search.trim());
    }

    // Adăugăm filtrele
    if (filters.county) {
      params.append('county', filters.county);
    }
    
    if (filters.caen) {
      // Extragem doar numărul din codul CAEN
      const caenCode = String(filters.caen).split(' ')[0];
      params.append('caen', caenCode);
    }
    
    if (filters.status) {
      params.append('status', filters.status);
    }

    const response = await fetch(`https://api.aipro.ro/cuie?${params.toString()}`);
    if (!response.ok) {
      throw new Error('Failed to fetch companies');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error searching companies:', error);
    throw error;
  }
};
export const quickSearchCompanies = async (searchTerm) => {
  try {
    if (!searchTerm || typeof searchTerm !== 'string') {
      return { data: [] };
    }

    const trimmedSearch = searchTerm.trim();
    if (trimmedSearch.length < 2) {
      return { data: [] };
    }

    // Construim URL-ul cu parametrii corect
    const url = new URL('https://api.aipro.ro/cuie');
    url.searchParams.append('searchText', trimmedSearch);
    url.searchParams.append('perPage', '10');

    console.log('Sending request with params:', {
      searchText: trimmedSearch,
      perPage: '10'
    });

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Search failed');
    }

    return await response.json();
  } catch (error) {
    console.error('Quick search error:', error);
    return { data: [] };
  }
};

export const findIndicatorValue = (data, indicatorName) => {
  if (!data?.i || !Array.isArray(data.i)) return 0;
  const indicator = data.i.find(item => 
    item?.val_den_indicator && 
    item.val_den_indicator.toLowerCase().includes(indicatorName.toLowerCase())
  );
  return indicator?.val_indicator ? parseFloat(indicator.val_indicator) : 0;
};

export const getYearlyData = (data) => {
  const years = Object.keys(data || {})
    .filter(key => key.startsWith('an'))
    .sort((a, b) => b.replace('an', '') - a.replace('an', ''));

  return years.map(year => ({
    year: year.replace('an', ''),
    data: data[year],
    cifraAfaceri: findIndicatorValue(data[year], 'Cifra de afaceri'),
    profitNet: findIndicatorValue(data[year], 'Profit net'),
    numarAngajati: findIndicatorValue(data[year], 'Numar mediu de salariati'),
    datorii: findIndicatorValue(data[year], 'DATORII'),
    activeImobilizate: findIndicatorValue(data[year], 'ACTIVE IMOBILIZATE'),
    activeCirculante: findIndicatorValue(data[year], 'ACTIVE CIRCULANTE')
  }));
};


const findIndicatorByCode = (data, code) => {
  if (!data?.i || !Array.isArray(data.i)) return 0;
  const indicator = data.i.find(item => item?.indicator === code);
  return indicator?.val_indicator ? parseFloat(indicator.val_indicator) : 0;
};



export const getIndicators = (data) => {
  const year = data?.an2023?.an ?? ''
  const currentYear = data?.an2023;
  const previousYear = data?.an2022;

  return {
    year,
    cifraAfaceri: {
      current: findIndicatorByCode(currentYear, 'I13'),
      previous: findIndicatorByCode(previousYear, 'I13')
    },
    profitNet: {
      current: findIndicatorByCode(currentYear, 'I18'),
      previous: findIndicatorByCode(previousYear, 'I18')
    },
    numarAngajati: {
      current: findIndicatorByCode(currentYear, 'I20'),
      previous: findIndicatorByCode(previousYear, 'I20')
    },
    datorii: {
      current: findIndicatorByCode(currentYear, 'I7'),
      previous: findIndicatorByCode(previousYear, 'I7')
    },
    activeImobilizate: {
      current: findIndicatorByCode(currentYear, 'I1'),
      previous: findIndicatorByCode(previousYear, 'I1')
    },
    activeCirculante: {
      current: findIndicatorByCode(currentYear, 'I2'),
      previous: findIndicatorByCode(previousYear, 'I2')
    }
  };
};

