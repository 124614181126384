import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { quickSearchCompanies } from '../services/api';
import { MagnifyingGlassIcon, ClockIcon, BuildingOfficeIcon } from '@heroicons/react/24/outline';

export default function SearchWithHistory({ onSelect }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const saved = localStorage.getItem('recentSearches');
    if (saved) {
      setRecentSearches(JSON.parse(saved));
    }
  }, []);

  useEffect(() => {
    const search = async () => {
      if (searchTerm.length >= 2) {
        const results = await quickSearchCompanies(searchTerm);
        setSearchResults(results.data);
      } else {
        setSearchResults([]);
      }
    };

    const debounce = setTimeout(search, 300);
    return () => clearTimeout(debounce);
  }, [searchTerm]);

  const handleCompanySelect = (company) => {
    const newRecent = [
      { 
        id: company.CUI, 
        name: company.nume_companie, 
        address: company.date_generale?.adresa 
      },
      ...recentSearches.filter(item => item.id !== company.CUI)
    ].slice(0, 5);
    
    setRecentSearches(newRecent);
    localStorage.setItem('recentSearches', JSON.stringify(newRecent));
    navigate(`/company/${company.CUI}`);
    if (onSelect) onSelect();
  };

  return (
    <div className="relative">
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Caută după CUI sau denumire..."
          className="w-full px-4 py-2 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          autoFocus
        />
        <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
      </div>

      {(searchResults.length > 0 || recentSearches.length > 0) && (
        <div className="relative z-10 w-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200 overflow-y-auto mb-5" style={{ maxHeight: '10rem'}}>
          {searchResults.length > 0 ? (
            <div className="py-1">
              {searchResults.map((company) => (
                <div
                  key={company.CUI}
                  onClick={() => handleCompanySelect(company)}
                  className="px-4 py-3 hover:bg-gray-100 cursor-pointer"
                >
                  <div className="flex items-start space-x-3">
                    <div className="flex-shrink-0">
                      <div className="w-10 h-10 bg-blue-100 rounded-lg flex items-center justify-center">
                        <BuildingOfficeIcon className="h-6 w-6 text-blue-600" />
                      </div>
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {company.nume_companie}
                      </p>
                      <p className="text-sm text-gray-500 truncate">
                        CUI: {company.CUI}
                      </p>
                      {company.date_generale?.adresa && (
                        <p className="text-sm text-gray-500 truncate">
                          {company.date_generale.adresa}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : recentSearches.length > 0 && !searchTerm && (
            <div className="py-1">
              <div className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">
                Căutări recente
              </div>
              {recentSearches.map((item) => (
                <div
                  key={item.id}
                  onClick={() => navigate(`/company/${item.id}`)}
                  className="px-4 py-3 hover:bg-gray-100 cursor-pointer"
                >
                  <div className="flex items-start space-x-3">
                    <div className="flex-shrink-0">
                      <div className="w-10 h-10 bg-gray-100 rounded-lg flex items-center justify-center">
                        <ClockIcon className="h-6 w-6 text-gray-600" />
                      </div>
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {item.name}
                      </p>
                      <p className="text-sm text-gray-500 truncate">
                        CUI: {item.id}
                      </p>
                      {item.address && (
                        <p className="text-sm text-gray-500 truncate">
                          {item.address}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}