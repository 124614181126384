import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { quickSearchCompanies } from '../services/api';

export default function CompanySearch() {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: ['quickSearch', searchTerm],
    queryFn: async () => {
      // Verificăm dacă avem un termen de căutare valid
      if (!searchTerm || searchTerm.trim().length < 2) {
        return { data: [] };
      }
      return quickSearchCompanies(searchTerm);
    },
    enabled: searchTerm.trim().length >= 2,
    staleTime: 1000 * 60 * 5 // 5 minute cache
  });
  
  const handleSearch = (e) => {
    const value = e.target.value;
    console.log('Search term:', value); // Pentru debugging
    setSearchTerm(value);
  };

  // Folosim debounce pentru a evita prea multe cereri



  const handleCompanyClick = (company) => {
    navigate(`/company/${company.CUI}`);
    setSearchTerm('');
  };

  return (
    <div className="relative">
      <div className="relative w-full">
        <input
          type="text"
          className="w-full px-4 py-2 pl-10 pr-4 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Caută după nume firmă sau CUI..."
          value={searchTerm}
          onChange={handleSearch}
          autoComplete="off"
        />
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          {isLoading ? (
            <div className="animate-spin rounded-full h-4 w-4 border-2 border-blue-500 border-t-transparent"></div>
          ) : (
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
          )}
        </div>
      </div>

      {searchTerm.length > 1 && (
        <div className="absolute z-50 w-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200">
          {isLoading ? (
            <div className="p-4 text-center text-gray-500">
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500 mx-auto"></div>
            </div>
          ) : data?.data?.length > 0 ? (
            <div className="py-1 max-h-96 overflow-y-auto">
              {data.data.map((company) => (
                <div
                  key={company.CUI}
                  className="px-4 py-2 hover:bg-gray-50 cursor-pointer transition duration-150"
                  onClick={() => handleCompanyClick(company)}
                >
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <div className="w-8 h-8 rounded-lg bg-blue-100 flex items-center justify-center">
                        <span className="text-blue-600 text-sm font-medium">
                          {company.nume_companie.charAt(0)}
                        </span>
                      </div>
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {company.nume_companie}
                      </p>
                      <p className="text-xs text-gray-500">
                        CUI: {company.CUI}
                      </p>
                      {company.adresa_sediu_social && (
                        <p className="text-xs text-gray-500 truncate">
                          {[
                            company.adresa_sediu_social.sdenumire_Strada,
                            company.adresa_sediu_social.snumar_Strada && `Nr. ${company.adresa_sediu_social.snumar_Strada}`,
                            company.adresa_sediu_social.sdenumire_Localitate,
                            company.adresa_sediu_social.sdenumire_Judet
                          ].filter(Boolean).join(', ')}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="p-4 text-center text-gray-500">
              Nu s-au găsit rezultate pentru "{searchTerm}"
            </div>
          )}
        </div>
      )}
    </div>
  );
}