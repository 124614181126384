import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCompanyDetails, getIndicators } from '../services/api';
import { Line } from 'react-chartjs-2';
import {
    BuildingOfficeIcon,
    IdentificationIcon,
    MapPinIcon,
    CalendarIcon,
    BanknotesIcon,
    ArrowTrendingUpIcon,
    ArrowTrendingDownIcon,
    DocumentTextIcon,
    PhoneIcon,
    ClockIcon,
    CheckCircleIcon,
    XCircleIcon
  } from '@heroicons/react/24/outline';
  import { CompanyRefreshButton } from './CompanyRefreshButton';

// Configurare Chart.js
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



export default function CompanyDetails() {
  const { cui } = useParams();
  
  const { data: company, isLoading, refetch } = useQuery({
    queryKey: ['company', cui],
    queryFn: () => getCompanyDetails(cui),
  });

  const calculateGrowth = (current, previous) => {
    if (!previous) return 0;
    return ((current - previous) / previous) * 100;
  };

  const formatMoney = (amount) => {
    return new Intl.NumberFormat('ro-RO', {
      style: 'currency',
      currency: 'RON',
      maximumFractionDigits: 0
    }).format(amount);
  };

  const InfoCard = ({ icon: Icon, label, value, growth }) => (
    <div className="bg-white rounded-lg shadow p-4 flex items-start space-x-4">
      <div className="p-3 bg-blue-50 rounded-lg">
        <Icon className="h-6 w-6 text-blue-600" />
      </div>
      <div className="flex-1">
        <p className="text-sm text-gray-500">{label}</p>
        <p className="font-semibold text-lg">{value}</p>
        {growth !== undefined && (
          <div className="flex items-center mt-1 space-x-1">
            {growth > 0 ? (
              <ArrowTrendingUpIcon className="h-4 w-4 text-green-500" />
            ) : (
              <ArrowTrendingDownIcon className="h-4 w-4 text-red-500" />
            )}
            <span className={`text-sm ${growth > 0 ? 'text-green-600' : 'text-red-600'}`}>
              {Math.abs(growth).toFixed(1)}%
            </span>
          </div>
        )}
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!company) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-gray-500">Nu s-au găsit date pentru această companie.</div>
      </div>
    );
  }

  const indicators = getIndicators(company);
  const StatusBadge = ({ active, text }) => (
    <span 
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
        active 
          ? 'bg-green-100 text-green-800' 
          : 'bg-red-100 text-red-800'
      }`}
    >
      {active ? (
        <CheckCircleIcon className="h-4 w-4 mr-1" />
      ) : (
        <XCircleIcon className="h-4 w-4 mr-1" />
      )}
      {text}
    </span>
  );
  return (
    <div className="container mx-auto px-4 py-8">
      {/* Header cu buton de actualizare */}
     
      <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
        <div className="flex justify-between items-start">
            <div>
            <h1 className="text-2xl font-bold text-gray-900 mb-2">
                {company?.nume_companie || 'N/A'}
            </h1>
            <div className="flex items-center gap-2 mb-2">
                <p className="text-gray-500">CUI: {company?.CUI}</p>
                {company?.date_generale?.data_inregistrare && (
                <span className="text-sm text-gray-500 flex items-center">
                    <ClockIcon className="h-4 w-4 mr-1" />
                    Din: {new Date(company.date_generale.data_inregistrare).toLocaleDateString('ro-RO')}
                </span>
                )}
            </div>
            <div className="flex gap-2 mt-2">
                <StatusBadge 
                active={!company?.stare_inactiv?.statusInactivi} 
                text={company?.stare_inactiv?.statusInactivi ? "Inactivă" : "Activă"} 
                />
                <StatusBadge 
                active={company?.inregistrare_scop_Tva?.scpTVA} 
                text="Plătitor TVA" 
                />
            </div>
            </div>
            
            <div className="flex flex-col items-end gap-2">
                <div className="text-sm text-gray-500">
                    {company?.last_update ? (
                    <span className="flex items-center">
                        <ClockIcon className="h-4 w-4 mr-1" />
                        Ultima actualizare: {new Date(company.last_update).toLocaleString('ro-RO')}
                    </span>
                    ) : 'Fără actualizări recente'}
                </div>
                <div className="flex items-center gap-2">
                    <CompanyRefreshButton 
                    cui={cui} 
                    onSuccess={() => {
                        refetch(); // Reîncărcăm datele după actualizare
                    }}
                    />
                </div>
                </div>
            </div>

        {/* Loading overlay pentru întregul card */}
        {isLoading && (
            <div className="absolute inset-0 bg-white bg-opacity-75 rounded-lg flex items-center justify-center">
            <div className="flex items-center space-x-2">
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"></div>
                <span className="text-sm text-gray-600">Se încarcă datele...</span>
            </div>
            </div>
        )}
        </div>
      {/* Indicatori financiari */}
      <h1 className='mb-2'>Date financiare {indicators.year ?? ''}</h1> 
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-8">
        <InfoCard
          icon={BuildingOfficeIcon}
          label="Cifra de Afaceri"
          value={formatMoney(indicators.cifraAfaceri.current)}
          growth={calculateGrowth(indicators.cifraAfaceri.current, indicators.cifraAfaceri.previous)}
        />
        <InfoCard
          icon={BanknotesIcon}
          label="Profit Net"
          value={formatMoney(indicators.profitNet.current)}
          growth={calculateGrowth(indicators.profitNet.current, indicators.profitNet.previous)}
        />
        <InfoCard
          icon={DocumentTextIcon}
          label="Număr Angajați"
          value={indicators.numarAngajati.current}
          growth={calculateGrowth(indicators.numarAngajati.current, indicators.numarAngajati.previous)}
        />
        <InfoCard
          icon={BanknotesIcon}
          label="Active Totale"
          value={formatMoney(indicators.activeImobilizate.current + indicators.activeCirculante.current)}
          growth={calculateGrowth(
            indicators.activeImobilizate.current + indicators.activeCirculante.current,
            indicators.activeImobilizate.previous + indicators.activeCirculante.previous
          )}
        />
      </div>

      {/* Detalii companie și grafic */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        {/* Detalii generale */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-lg font-semibold mb-4">Detalii generale</h2>
          <div className="space-y-4">
            <div className="flex items-center space-x-3">
              <IdentificationIcon className="h-5 w-5 text-gray-400" />
              <div>
                <p className="text-sm text-gray-500">Nr. Înmatriculare</p>
                <p className="font-medium">{company.date_generale?.nrRegCom}</p>
              </div>
            </div>
            
            <div className="flex items-center space-x-3">
              <MapPinIcon className="h-5 w-5 text-gray-400" />
              <div>
                <p className="text-sm text-gray-500">Adresă</p>
                <p className="font-medium">{company.date_generale?.adresa}</p>
              </div>
            </div>

            <div className="flex items-center space-x-3">
              <PhoneIcon className="h-5 w-5 text-gray-400" />
              <div>
                <p className="text-sm text-gray-500">Contact</p>
                <p className="font-medium">
                  Tel: {company.date_generale?.telefon || 'N/A'}
                  {company.date_generale?.fax && ` | Fax: ${company.date_generale.fax}`}
                </p>
              </div>
            </div>

            <div className="flex items-center space-x-3">
              <CalendarIcon className="h-5 w-5 text-gray-400" />
              <div>
                <p className="text-sm text-gray-500">Data Înregistrării</p>
                <p className="font-medium">{new Date(company.date_generale.data_inregistrare).toLocaleDateString('ro-RO')}</p>
              </div>
            </div>

            <div className="flex items-center space-x-3">
              <DocumentTextIcon className="h-5 w-5 text-gray-400" />
              <div>
                <p className="text-sm text-gray-500">Cod CAEN</p>
                <p className="font-medium">{company.date_generale?.cod_CAEN} - {company.an2023?.den_caen}</p>
              </div>
            </div>
          </div>

          {/* Informații suplimentare */}
          <div className="mt-6">
            <h3 className="text-md font-semibold mb-3">Informații suplimentare</h3>
            <div className="grid grid-cols-1 gap-3">
              <div className="text-sm">
                <span className="text-gray-500">Forma juridică:</span>
                <span className="ml-2">{company.date_generale?.forma_juridica}</span>
              </div>
              <div className="text-sm">
                <span className="text-gray-500">Tip proprietate:</span>
                <span className="ml-2">{company.date_generale?.forma_de_proprietate}</span>
              </div>
              <div className="text-sm">
                <span className="text-gray-500">Organ fiscal:</span>
                <span className="ml-2">{company.date_generale?.organFiscalCompetent}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Grafic evoluție */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-lg font-semibold mb-4">Evoluție financiară</h2>
          <div className="h-[400px]">
            <Line
              data={{
                labels: Object.keys(company)
                  .filter(key => key.startsWith('an'))
                  .map(year => year.replace('an', ''))
                  .sort(),
                datasets: [
                  {
                    label: 'Cifra de afaceri',
                    data: Object.keys(company)
                      .filter(key => key.startsWith('an'))
                      .sort()
                      .map(year => {
                        const yearData = company[year]?.i;
                        return yearData?.find(i => i.indicator === 'I13')?.val_indicator || 0;
                      }),
                    borderColor: 'rgb(59, 130, 246)',
                    backgroundColor: 'rgba(59, 130, 246, 0.5)',
                  },
                  {
                    label: 'Profit net',
                    data: Object.keys(company)
                      .filter(key => key.startsWith('an'))
                      .sort()
                      .map(year => {
                        const yearData = company[year]?.i;
                        return yearData?.find(i => i.indicator === 'I18')?.val_indicator || 0;
                      }),
                    borderColor: 'rgb(34, 197, 94)',
                    backgroundColor: 'rgba(34, 197, 94, 0.5)',
                  },
                  {
                    label: 'Datorii',
                    data: Object.keys(company)
                      .filter(key => key.startsWith('an'))
                      .sort()
                      .map(year => {
                        const yearData = company[year]?.i;
                        return yearData?.find(i => i.indicator === 'I7')?.val_indicator || 0;
                      }),
                    borderColor: 'rgb(239, 68, 68)',
                    backgroundColor: 'rgba(239, 68, 68, 0.5)',
                  }
                ]
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      callback: (value) => formatMoney(value)
                    }
                  }
                },
                plugins: {
                  legend: {
                    position: 'bottom'
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        let label = context.dataset.label || '';
                        if (label) {
                          label += ': ';
                        }
                        if (context.parsed.y !== null) {
                          label += formatMoney(context.parsed.y);
                        }
                        return label;
                      }
                    }
                  }
                }
              }}
            />
          </div>
        </div>
      </div>

      {/* Tabel cu indicatori financiari */}
      <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
        <h2 className="text-lg font-semibold mb-4">Indicatori financiari detaliați</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Indicator
                </th>
                {Object.keys(company)
                  .filter(key => key.startsWith('an'))
                  .sort((a, b) => b.replace('an', '') - a.replace('an', ''))
                  .map(year => (
                    <th key={year} scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {year.replace('an', '')}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {[
                { code: 'I13', name: 'Cifra de afaceri' },
                { code: 'I18', name: 'Profit net' },
                { code: 'I20', name: 'Număr mediu de salariați' },
                { code: 'I1', name: 'Active imobilizate' },
                { code: 'I2', name: 'Active circulante' },
                { code: 'I7', name: 'Datorii' },
                { code: 'I10', name: 'Capitaluri proprii' },
                { code: 'I14', name: 'Venituri totale' },
                { code: 'I15', name: 'Cheltuieli totale' }
              ].map((indicator, idx) => (
                <tr key={indicator.code} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {indicator.name}
                  </td>
                  {Object.keys(company)
                    .filter(key => key.startsWith('an'))
                    .sort((a, b) => b.replace('an', '') - a.replace('an', ''))
                    .map(year => {
                      const value = company[year]?.i?.find(i => i.indicator === indicator.code)?.val_indicator;
                      return (
                        <td key={`${year}-${indicator.code}`} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {indicator.code === 'I20' ? 
                            value || '0' : 
                            formatMoney(value || 0)
                          }
                        </td>
                      );
                    })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* TVA și alte informații */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-lg font-semibold mb-4">Informații TVA</h2>
          <div className="space-y-4">
            <div>
              <StatusBadge 
                active={company.inregistrare_scop_Tva?.scpTVA} 
                text="Înregistrat în scopuri de TVA" 
              />
              {company.inregistrare_scop_Tva?.perioade_TVA?.map((perioada, index) => (
                <div key={index} className="mt-2 text-sm text-gray-600">
                  <p>Data început: {perioada.data_inceput_ScpTVA ? new Date(perioada.data_inceput_ScpTVA).toLocaleDateString('ro-RO') : 'N/A'}</p>
                  {perioada.data_sfarsit_ScpTVA && (
                    <p>Data sfârșit: {perioada.data_sfarsit_ScpTVA ? new Date(perioada.data_sfarsit_ScpTVA).toLocaleDateString('ro-RO') : 'N/A'}</p>
                  )}
                </div>
              ))}
            </div>
            
            {company.date_generale?.statusRO_e_Factura && (
              <div className="mt-4">
                <StatusBadge active={true} text="e-Factura" />
                <p className="mt-2 text-sm text-gray-600">
                  Data înregistrare: {company.date_generale?.data_inreg_Reg_RO_e_Factura}
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-lg font-semibold mb-4">Status companie</h2>
          <div className="space-y-4">
            <div>
              <StatusBadge 
                active={!company.stare_inactiv?.statusInactivi} 
                text="Status fiscal" 
              />
              <p className="mt-2 text-sm text-gray-600">
                Stare: {company.date_generale?.stare_inregistrare}
              </p>
            </div>
            
            {company.stare_inactiv?.dataInactivare && (
              <div className="mt-2 text-sm text-gray-600">
                <p>Data inactivare: {company.stare_inactiv.dataInactivare ? new Date(company.stare_inactiv.dataInactivare).toLocaleDateString('ro-RO') : 'N/A'}</p>
                {company.stare_inactiv.dataReactivare && (
                  <p>Data reactivare: {company.stare_inactiv.dataReactivare ? new Date(company.stare_inactiv.dataReactivare).toLocaleDateString('ro-RO') : 'N/A'}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
  
}
