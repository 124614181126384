import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCompanyStats } from '../services/api';
import { 
  BuildingOfficeIcon,
  BuildingStorefrontIcon,
  XCircleIcon
} from '@heroicons/react/24/outline';
import SearchWithHistory from './SearchWithHistory';
import CompaniesList from './CompaniesList';

const StatCard = ({ title, value, icon: Icon, description, color = "blue" }) => (
  <div className={`bg-white rounded-lg shadow p-6 border-l-4 border-${color}-500`}>
    <div className="flex items-center justify-between">
      <div>
        <p className="text-gray-500 text-sm">{title}</p>
        <h3 className="text-2xl font-bold mt-1">{value.toLocaleString()}</h3>
        {description && (
          <p className="text-gray-500 text-xs mt-1">{description}</p>
        )}
      </div>
      <Icon className={`w-12 h-12 text-${color}-500`} />
    </div>
  </div>
);

export default function Dashboard() {
  const { data: stats, isLoading } = useQuery({
    queryKey: ['companyStats'],
    queryFn: getCompanyStats
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <StatCard
          title="Total Companii"
          value={stats?.total || 0}
          icon={BuildingOfficeIcon}
          description="Toate companiile din baza de date"
        />
        <StatCard
          title="Companii Active"
          value={stats?.active || 0}
          icon={BuildingStorefrontIcon}
          color="green"
          description="Companii cu activitate în ultimul an"
        />
        <StatCard
          title="Companii Inactive"
          value={stats?.inactive || 0}
          icon={XCircleIcon}
          color="red"
          description="Companii inactive sau radiate"
        />
      </div>

      {/* Search Section */}
      <div className="max-w-2xl mx-auto">
        <SearchWithHistory />
      </div>

      {/* Companies List with Counties */}
      <CompaniesList />
    </div>
  );
}