import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ArrowPathIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';

export function CompanyRefreshButton({ cui, onSuccess, className = '' }) {
  const queryClient = useQueryClient();
  const [loadingText, setLoadingText] = useState('Actualizare date');
  const [status, setStatus] = useState('idle'); // idle, loading, success, error

  const updateMutation = useMutation({
    mutationFn: async () => {
      try {
        setLoadingText('Se contactează ANAF...');
        setStatus('loading');

        const response = await fetch(`https://api.aipro.ro/update-company?cui=${cui}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Cache-Control': 'no-cache'
          },
        });

        if (!response.ok) {
          const errorData = await response.json().catch(() => ({}));
          throw new Error(errorData.message || 'Eroare la actualizarea datelor');
        }

        const data = await response.json();
        setLoadingText('Se procesează datele...');
        return data;
      } catch (error) {
        console.error('Update error:', error);
        throw error;
      }
    },
    onSuccess: async (data) => {
      setStatus('success');
      setLoadingText('Date actualizate cu succes!');
      await queryClient.invalidateQueries(['company', cui]);
      if (onSuccess) {
        onSuccess(data);
      }
      setTimeout(() => {
        setLoadingText('Se actualizează...');
        setStatus('idle');
      }, 2000);
    },
    onError: (error) => {
      console.error('Error:', error);
      setStatus('error');
      setLoadingText(error.message || 'Eroare la actualizare!');
      setTimeout(() => {
        setLoadingText('Actualizare date');
        setStatus('idle');
      }, 2000);
    }
  });

  const handleClick = async () => {
    if (updateMutation.isLoading) return;

    try {
      await updateMutation.mutateAsync();
    } catch (error) {
      console.error('Handle click error:', error);
    }
  };

  const getButtonStyle = () => {
    switch (status) {
      case 'loading':
        return 'bg-blue-50 text-blue-700 border-blue-200';
      case 'success':
        return 'bg-green-50 text-green-700 border-green-200';
      case 'error':
        return 'bg-red-50 text-red-700 border-red-200';
      default:
        return 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50';
    }
  };

  const getIcon = () => {
    switch (status) {
      case 'loading':
        return <ArrowPathIcon className="h-4 w-4 mr-2 animate-spin" />;
      case 'success':
        return <CheckCircleIcon className="h-4 w-4 mr-2 text-green-600" />;
      case 'error':
        return <XCircleIcon className="h-4 w-4 mr-2 text-red-600" />;
      default:
        return <ArrowPathIcon className="h-4 w-4 mr-2" />;
    }
  };

  return (
    <button
      onClick={handleClick}
      disabled={status === 'loading'}
      className={`
        inline-flex items-center px-4 py-2 border rounded-md text-sm font-medium
        ${getButtonStyle()}
        shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
        transition-all duration-200 ease-in-out
        disabled:opacity-75 disabled:cursor-not-allowed
        ${className}
      `}
    >
      {getIcon()}
      <span className="whitespace-nowrap min-w-[120px] text-left">
        {loadingText}
      </span>
    </button>
  );
}
