import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { BuildingOfficeIcon, NoSymbolIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { searchCompanies } from '../services/api';
import CompanyFilters from './CompanyFilters';
import AddCompanyModal from './AddCompanyModal';

export default function CompaniesList() {
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const navigate = useNavigate();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['companies', filters, page],
    queryFn: () => searchCompanies({
      filters,
      page,
      perPage: 10
    }),
    keepPreviousData: true
  });

  const handleFilterChange = (newFilters) => {
    console.log('New filters:', newFilters); // Pentru debugging
    setPage(1);
    setFilters(newFilters);
  };

  const formatAddress = (company) => {
    const sediu = company.adresa_sediu_social;
    if (!sediu) return 'Adresă nedisponibilă';

    const parts = [];
    if (sediu.sdenumire_Strada) parts.push(sediu.sdenumire_Strada);
    if (sediu.snumar_Strada) parts.push(`Nr. ${sediu.snumar_Strada}`);
    if (sediu.sdenumire_Localitate) parts.push(sediu.sdenumire_Localitate);
    if (sediu.sdenumire_Judet) parts.push(sediu.sdenumire_Judet);

    return parts.join(', ') || 'Adresă nedisponibilă';
  };

  const getCAENInfo = (company) => {
    const caenCode = company.date_generale?.cod_CAEN;
    const caenName = company.an2023?.den_caen;
    
    if (caenCode && caenName) {
      return `${caenCode} - ${caenName}`;
    } else if (caenCode) {
      return `CAEN: ${caenCode}`;
    }
    return null;
  };

  return (
    <div className="space-y-6">
      {/* Filtre */}
      <CompanyFilters 
        onFilterChange={handleFilterChange}
        activeFilters={filters}
        onAddCompany={() => setIsAddModalOpen(true)}
      />

      {/* Modal pentru adăugare companie */}
      <AddCompanyModal 
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      />

      {/* Lista de companii */}
      <div className="bg-white rounded-lg shadow">
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        ) : data?.data?.length > 0 ? (
          <>
            {/* Header cu număr total */}
            <div className="border-b border-gray-200 px-6 py-4 flex items-center justify-between">
              <h2 className="font-semibold text-gray-900">
                {data.total} {data.total === 1 ? 'firmă găsită' : 'firme găsite'}
              </h2>
              {Object.keys(filters).length > 0 && (
                <button
                  onClick={() => setFilters({})}
                  className="text-sm text-gray-500 hover:text-gray-700 flex items-center"
                >
                  <XMarkIcon className="h-4 w-4 mr-1" />
                  Resetează filtrele
                </button>
              )}
            </div>

            {/* Lista companiilor */}
            <div className="divide-y divide-gray-200">
              {data.data.map((company) => (
                <div
                  key={company.CUI}
                  onClick={() => navigate(`/company/${company.CUI}`)}
                  className="p-6 hover:bg-gray-50 cursor-pointer transition-colors duration-150"
                >
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0">
                      <div className="h-12 w-12 bg-blue-100 rounded-lg flex items-center justify-center">
                        {company.stare_inactiv?.statusInactivi ? (
                          <NoSymbolIcon className="h-6 w-6 text-red-600" />
                        ) : (
                          <BuildingOfficeIcon className="h-6 w-6 text-blue-600" />
                        )}
                      </div>
                    </div>
                    
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center gap-2">
                        <h3 className="text-base font-medium text-gray-900 truncate">
                          {company.nume_companie}
                        </h3>
                        {company.stare_inactiv?.statusInactivi && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                            Inactivă
                          </span>
                        )}
                      </div>
                      
                      <p className="mt-1 text-sm text-gray-500">
                        CUI: {company.CUI}
                      </p>
                      <p className="mt-1 text-sm text-gray-500 truncate">
                        {formatAddress(company)}
                      </p>
                      
                      {getCAENInfo(company) && (
                        <p className="mt-1 text-sm text-gray-500">
                          {getCAENInfo(company)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Paginare */}
            <div className="flex items-center justify-between px-6 py-4 border-t border-gray-200">
              <div>
                <p className="text-sm text-gray-700">
                  Afișare{' '}
                  <span className="font-medium">{((page - 1) * 10) + 1}</span>
                  {' '}-{' '}
                  <span className="font-medium">
                    {Math.min(page * 10, data.total)}
                  </span>
                  {' '}din{' '}
                  <span className="font-medium">{data.total}</span>
                  {' '}rezultate
                </p>
              </div>
              <div className="flex gap-2">
                <button
                  onClick={() => setPage(old => Math.max(old - 1, 1))}
                  disabled={page === 1 || isFetching}
                  className={`
                    relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md
                    ${page === 1
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-white text-gray-700 hover:bg-gray-50 border-gray-300'
                    }
                  `}
                >
                  Anterior
                </button>
                <button
                  onClick={() => setPage(old => old + 1)}
                  disabled={page >= data.totalPages || isFetching}
                  className={`
                    relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md
                    ${page >= data.totalPages
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-white text-gray-700 hover:bg-gray-50 border-gray-300'
                    }
                  `}
                >
                  Următor
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center h-64">
            <BuildingOfficeIcon className="h-12 w-12 text-gray-400 mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-1">
              Nu s-au găsit firme
            </h3>
            <p className="text-gray-500 text-sm text-center max-w-sm">
              {Object.keys(filters).length > 0
                ? 'Încercați să modificați filtrele pentru a vedea mai multe rezultate'
                : 'Folosiți filtrele de mai sus pentru a găsi firme'}
            </p>
          </div>
        )}
      </div>

      {/* Loading overlay pentru paginare */}
      {isFetching && !isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-4 flex items-center space-x-3">
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"></div>
            <span className="text-sm text-gray-700">Se încarcă...</span>
          </div>
        </div>
      )}
    </div>
  );
}