import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  BuildingOfficeIcon,
  MapPinIcon,
  ChevronRightIcon,
  CheckIcon,
  AdjustmentsHorizontalIcon
} from '@heroicons/react/24/outline';

// Lista completă de județe din România
const COUNTIES = [
  "ALBA", "ARAD", "ARGEȘ", "BACĂU", "BIHOR", "BISTRIȚA-NĂSĂUD", "BOTOȘANI", 
  "BRĂILA", "BRAȘOV", "BUCUREȘTI", "BUZĂU", "CĂLĂRAȘI", "CARAȘ-SEVERIN", 
  "CLUJ", "CONSTANȚA", "COVASNA", "DÂMBOVIȚA", "DOLJ", "GALAȚI", "GIURGIU", 
  "GORJ", "HARGHITA", "HUNEDOARA", "IALOMIȚA", "IAȘI", "ILFOV", "MARAMUREȘ", 
  "MEHEDINȚI", "MUREȘ", "NEAMȚ", "OLT", "PRAHOVA", "SĂLAJ", "SATU MARE", 
  "SIBIU", "SUCEAVA", "TELEORMAN", "TIMIȘ", "TULCEA", "VÂLCEA", "VASLUI", "VRANCEA"
];

export default function CompanyFilters({ onFilterChange, activeFilters }) {
  const [isOpen, setIsOpen] = useState(true);
  const [activeTab, setActiveTab] = useState('county');
  const [searchTerm, setSearchTerm] = useState('');

  // Query pentru statistici CAEN
  const { data: caenStats } = useQuery({
    queryKey: ['caen-stats', activeFilters.county],
    queryFn: async () => {
      const response = await fetch(`https://api.aipro.ro/caen-stats${
        activeFilters.county ? `?county=${encodeURIComponent(activeFilters.county)}` : ''
      }`);
      return response.json();
    },
  });

  const filteredCounties = COUNTIES.filter(county => 
    !searchTerm || county.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (type, value) => {
    const newFilters = { ...activeFilters };
    if (newFilters[type] === value) {
      delete newFilters[type];
    } else {
      newFilters[type] = value;
    }
    onFilterChange(newFilters);
  };

  return (
    <div className="bg-white rounded-lg shadow">
      {/* Header */}
      <div className="p-4 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <h2 className="font-semibold text-gray-900">Filtre</h2>
          <button 
            onClick={() => setIsOpen(!isOpen)} 
            className="text-gray-500 hover:text-gray-700"
          >
            <ChevronRightIcon 
              className={`h-5 w-5 transform transition-transform ${isOpen ? 'rotate-90' : ''}`} 
            />
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="p-4">
          {/* Tabs */}
          <div className="flex space-x-4 mb-4">
            <button
              onClick={() => setActiveTab('county')}
              className={`flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                activeTab === 'county' ? 'bg-blue-50 text-blue-700' : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              <MapPinIcon className="h-5 w-5 mr-2" />
              Județe
            </button>
            <button
              onClick={() => setActiveTab('caen')}
              className={`flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                activeTab === 'caen' ? 'bg-blue-50 text-blue-700' : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              <BuildingOfficeIcon className="h-5 w-5 mr-2" />
              Cod CAEN
            </button>
            <button
              onClick={() => setActiveTab('status')}
              className={`flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                activeTab === 'status' ? 'bg-blue-50 text-blue-700' : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              <AdjustmentsHorizontalIcon className="h-5 w-5 mr-2" />
              Status
            </button>
          </div>

          {/* Search input */}
          {activeTab !== 'status' && (
            <div className="mb-4">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={`Caută ${activeTab === 'county' ? 'județe' : 'coduri CAEN'}...`}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          )}

          {/* Content area */}
          <div className="max-h-96 overflow-y-auto">
            {activeTab === 'county' && (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
                {filteredCounties.map((county) => (
                  <button
                    key={county}
                    onClick={() => handleSelect('county', county)}
                    className={`flex items-center justify-between px-3 py-2 text-sm rounded-md ${
                      activeFilters.county === county
                        ? 'bg-blue-50 text-blue-700'
                        : 'hover:bg-gray-50'
                    }`}
                  >
                    <span className="truncate">{county}</span>
                    {activeFilters.county === county && (
                      <CheckIcon className="h-4 w-4 ml-2 flex-shrink-0" />
                    )}
                  </button>
                ))}
              </div>
            )}

            {activeTab === 'caen' && (
              <div className="space-y-2">
                {caenStats?.filter(caen =>
                  !searchTerm || 
                  caen.cod?.toString().includes(searchTerm) ||
                  caen.denumire?.toLowerCase().includes(searchTerm.toLowerCase())
                ).map((caen) => (
                  <button
                    key={caen.cod}
                    onClick={() => handleSelect('caen', caen.cod)}
                    className={`flex items-start justify-between w-full px-3 py-2 text-sm rounded-md ${
                      activeFilters.caen === caen.cod
                        ? 'bg-blue-50 text-blue-700'
                        : 'hover:bg-gray-50'
                    }`}
                  >
                    <div className="flex-1">
                      <div className="font-medium">
                        {caen.cod} - {caen.count} firme
                      </div>
                      <div className="text-xs text-gray-500">{caen.denumire}</div>
                    </div>
                    {activeFilters.caen === caen.cod && (
                      <CheckIcon className="h-4 w-4 flex-shrink-0 ml-2" />
                    )}
                  </button>
                ))}
              </div>
            )}

            {activeTab === 'status' && (
              <div className="space-y-2">
                {[
                  { id: 'active', name: 'Firme active' },
                  { id: 'inactive', name: 'Firme inactive' }
                ].map((status) => (
                  <button
                    key={status.id}
                    onClick={() => handleSelect('status', status.id)}
                    className={`flex items-center justify-between w-full px-3 py-2 text-sm rounded-md ${
                      activeFilters.status === status.id
                        ? 'bg-blue-50 text-blue-700'
                        : 'hover:bg-gray-50'
                    }`}
                  >
                    {status.name}
                    {activeFilters.status === status.id && (
                      <CheckIcon className="h-4 w-4" />
                    )}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Active filters */}
      {Object.keys(activeFilters).length > 0 && (
        <div className="px-4 py-2 border-t border-gray-200">
          <div className="flex flex-wrap gap-2">
            {Object.entries(activeFilters).map(([key, value]) => (
              <span
                key={key}
                className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-blue-50 text-blue-700"
              >
                {key === 'caen' 
                  ? `CAEN: ${value}${caenStats?.find(c => c.cod === value)?.count ? ` (${caenStats.find(c => c.cod === value).count} firme)` : ''}`
                  : key === 'status' 
                    ? value === 'active' ? 'Firme active' : 'Firme inactive'
                    : value}
                <button
                  onClick={() => handleSelect(key, value)}
                  className="ml-1 hover:text-blue-900"
                >
                  ×
                </button>
              </span>
            ))}
            <button
              onClick={() => onFilterChange({})}
              className="text-xs text-gray-500 hover:text-gray-700"
            >
              Resetează filtrele
            </button>
          </div>
        </div>
      )}
    </div>
  );
}